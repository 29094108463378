import './MultiColumnA.scss';

import { b2x } from '@b2x/react/src';

import { Button } from '../Button';
import { Container } from '../Container';
import { isIconName } from '../Icon';

export interface MultiColumnAContentType {
  head?: {
    content?: string;
    cta?: b2x.contentTypes.CtaContentSection;
    title?: string;
  };
  tiles?: b2x.contentTypes.ContentSectionList<{
    asset?: b2x.contentTypes.AssetContentSectionV2;
    content?: string;
    cta?: b2x.contentTypes.CtaContentSection;
    textAlignment?: string;
    title?: string;
  }>;
}

interface MultiColumnAProps extends MultiColumnAContentType {
  mainComponent?: boolean;
}

export const MultiColumnA = ({ head, mainComponent, tiles }: MultiColumnAProps) => {
  const { getPagePath } = b2x.useAppStaticContext();

  return (
    <b2x.Div className="multi-column-a" paddingY={{ lg: 5, xs: 3 }}>
      <Container>
        <b2x.Div
          className="component-header"
          marginBottom={{
            lg: 4,
            xs: 3,
          }}
          textAlign={'center'}
        >
          <b2x.ConditionalWrapper
            condition={head?.title !== undefined}
            wrapper={mainComponent ? <b2x.H1 className="title" /> : <b2x.P className="title" />}
          >
            {b2x.formatHtml(head?.title)}
          </b2x.ConditionalWrapper>
          {head?.content && (
            <b2x.P className="content" marginBottom={{ lg: 3 }}>
              {b2x.formatHtml(head.content)}
            </b2x.P>
          )}
          {head?.cta?.label && (
            <b2x.Div display={{ lg: 'block', xs: 'none' }}>
              <b2x.CtaFromContent {...head.cta} />
            </b2x.Div>
          )}
        </b2x.Div>
        <b2x.Div className="tiles">
          <b2x.EqualHeight>
            <b2x.SwiperContext>
              {({ navigationNextElRef, navigationPrevElRef, swiper }) => (
                <b2x.Div
                  className="products-swiper-container"
                  paddingX={tiles?.length && tiles.length > 3 ? { lg: 5, xs: 0 } : undefined}
                >
                  <b2x.Swiper
                    breakpoints={{
                      lg: {
                        slidesPerView: tiles?.length && tiles.length > 3 ? 4 : tiles?.length,
                      },
                      md: {
                        slidesPerView: 2.3,
                      },
                      xs: {
                        slidesPerView: 1,
                      },
                    }}
                    navigation={{ custom: true }}
                    pagination
                    slides={tiles?.map(({ asset, content, contentSectionId, cta, textAlignment, title }) => {
                      const href = cta?.to?.href;
                      const code = cta?.to?.code && getPagePath(cta.to.code);
                      const to = href ? href : code;

                      return (
                        <b2x.Div
                          className="tile"
                          key={contentSectionId}
                          textAlign={{ lg: textAlignment as b2x.TextAlign, xs: 'center' }}
                        >
                          {asset?.type && (
                            <b2x.Div
                              className="tile-asset"
                              marginBottom={title || content || cta?.label ? 3 : undefined}
                            >
                              <b2x.EqualHeightElement name="tile-asset">
                                <b2x.router.Link to={to}>
                                  <b2x.AssetV2 {...asset} fluid />
                                </b2x.router.Link>
                              </b2x.EqualHeightElement>
                            </b2x.Div>
                          )}
                          {(title || content || cta?.label) && (
                            <b2x.Div className="copy">
                              {title && (
                                <b2x.EqualHeightElement name="title-content">
                                  <b2x.P className="tile-title" margin={0}>
                                    <b2x.router.Link to={to}>{b2x.formatHtml(title)}</b2x.router.Link>
                                  </b2x.P>
                                </b2x.EqualHeightElement>
                              )}
                              {content && (
                                <b2x.EqualHeightElement name="tile-content">
                                  <b2x.P className="tile-content" marginBottom={3}>
                                    {b2x.formatHtml(content)}
                                  </b2x.P>
                                </b2x.EqualHeightElement>
                              )}
                              {cta?.label && (
                                <b2x.CtaFromContent
                                  {...cta}
                                  ctaProps={{ button: { iconEnd: { name: 'arrow-right-bold', size: 10 } } }}
                                />
                              )}
                            </b2x.Div>
                          )}
                        </b2x.Div>
                      );
                    })}
                    spaceBetween={20}
                    watchSlidesProgress
                  />
                  <b2x.Div
                    className="position-absolute w-100 h-100 top-0 start-0"
                    display={{ lg: 'block', xs: 'none' }}
                  >
                    <b2x.EqualHeightElement name={'product-media-container'}>
                      {b2x.appConfig.icons?.slider?.arrow?.left &&
                        isIconName(b2x.appConfig.icons.slider.arrow.left) && (
                          <b2x.Div display={swiper?.isBeginning ? 'none' : 'block'}>
                            <Button
                              className="swiper-arrow swiper-arrow-left"
                              iconStart={{ name: b2x.appConfig.icons.slider.arrow.left, size: 34 }}
                              innerRef={navigationPrevElRef}
                              variant="blank"
                            />
                          </b2x.Div>
                        )}
                      {b2x.appConfig.icons?.slider?.arrow?.right &&
                        isIconName(b2x.appConfig.icons.slider.arrow.right) && (
                          <b2x.Div display={swiper?.isEnd ? 'none' : 'block'}>
                            <Button
                              className="swiper-arrow swiper-arrow-right"
                              iconStart={{ name: b2x.appConfig.icons.slider.arrow.right, size: 34 }}
                              innerRef={navigationNextElRef}
                              variant="blank"
                            />
                          </b2x.Div>
                        )}
                    </b2x.EqualHeightElement>
                  </b2x.Div>
                </b2x.Div>
              )}
            </b2x.SwiperContext>
          </b2x.EqualHeight>
          {head?.cta?.label && (
            <b2x.Div display={{ lg: 'none' }} marginTop={2} textAlign={'center'}>
              <b2x.CtaFromContent {...head.cta} variant="outline-primary" />
            </b2x.Div>
          )}
        </b2x.Div>
      </Container>
    </b2x.Div>
  );
};
