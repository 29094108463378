// eslint-disable-next-line no-restricted-imports
import { api, SurveyAnswerQuestionRequestApiDto, SurveySessionInitRequestApiDto } from '@b2x/storefront-api-js-client/src';
import { GetSurveyInfoOptions, GetSurveyResultOptions,GetSurveySessionOptions } from '@b2x/storefront-api-js-client/src/survey';
import { BaseOptions } from '@b2x/storefront-api-js-client/src/utils/util';
import React from 'react';

import { ApiRequestConfig, useApiRequest } from './useApiRequest';

export const useSurveyApi = () => {
  const { apiRequest } = useApiRequest();

  const getSurveySession = React.useCallback(
      (options?: GetSurveySessionOptions, config?: ApiRequestConfig) =>
        apiRequest(api.survey.getSurveySession(options), { ...config }),
      [apiRequest]
    );

  const initSurveySession = React.useCallback(
      (data: SurveySessionInitRequestApiDto, options?: BaseOptions, config?: ApiRequestConfig) =>
        apiRequest(api.survey.initSurveySession(data, options), { ...config }),
      [apiRequest]
    );

  const getSurveyInfo = React.useCallback(
      (options?: GetSurveyInfoOptions, config?: ApiRequestConfig) =>
        apiRequest(api.survey.getSurveyInfo(options), { ...config }),
      [apiRequest]
    );

  const setSurveySessionAnswer = React.useCallback(
      (data: SurveyAnswerQuestionRequestApiDto, options?: BaseOptions, config?: ApiRequestConfig) =>
        apiRequest(api.survey.setSurveySessionAnswer(data, options), { ...config }),
      [apiRequest]
    );

  const getSurveySessionResult = React.useCallback(
      (options?: GetSurveyResultOptions, config?: ApiRequestConfig) =>
        apiRequest(api.survey.getSurveySessionResult(options), { ...config }),
      [apiRequest]
    );

  return { getSurveyInfo, getSurveySession, getSurveySessionResult, initSurveySession, setSurveySessionAnswer };
};
