import './RegistrationPage.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { Box } from '../Box';
import { Button } from '../Button';
import { Container } from '../Container';
import { RegistrationFormContentType, WhoisPageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Icon, IconName, isIconName } from '../Icon';
import { Page } from './Page';

export interface WhoisPageProps {}

export const WhoisPage = (props: WhoisPageProps) => {
  const [register, setRegister] = React.useState<boolean>(false);

  const handleButtonClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setRegister(true);
  }, []);

  const handleCancelButtonClick = React.useCallback(() => {
    console.log('cancel click');
    setRegister(false);
  }, []);
  const whoisContent = b2x.useContent<WhoisPageContentType>('WHOIS_PAGE_CONTENT');
  const registrationContent = b2x.useContent<RegistrationFormContentType>('REGISTRATION_FORM_CONTENT');

  const navigate = b2x.router.useNavigate();

  const handleLoginSuccess = React.useCallback(() => {
    navigate('/checkout');
  }, [navigate]);

  const handleRegistrationSuccess = React.useCallback(() => {
    navigate('/checkout');
  }, [navigate]);

  return (
    <Page className="whois-page" headerCheckout nudeBackground>
      <Container>
        <b2x.Row className="justify-content-center">
          <b2x.Col size={{ md: 6, xl: 5, xs: 12 }}>
            <Box fullHeight>
              <div className="border-bottom mb-3 border-gray-200">
                <h3 className="text-center text-uppercase fw-medium">
                  {b2x.formatHtml(whoisContent?.body.continueAsRegisteredUser?.title)}
                </h3>
              </div>
              <div className="mb-4 text-center">
                {b2x.formatHtml(whoisContent?.body.continueAsRegisteredUser?.subTitle)}
              </div>
              <b2x.LoginForm onSuccess={handleLoginSuccess} />
            </Box>
          </b2x.Col>
          <b2x.Col size={{ md: 6, xl: 5, xs: 12 }}>
            <Box className="mt-3 mt-md-0">
              {!register && (
                <>
                  <div className="mb-4">
                    <div className="border-bottom mb-3 border-gray-200">
                      <h3 className="text-center text-uppercase fw-medium">
                        {b2x.formatHtml(whoisContent?.body.continueAsGuest?.title)}
                      </h3>
                    </div>
                    <div className="mb-3 text-center fw-semi-bold">
                      {b2x.formatHtml(whoisContent?.body.continueAsGuest?.registerMe?.content)}
                    </div>
                    {registrationContent?.body.iconsList && (
                      <Container>
                        <b2x.Row gap={3}>
                          {registrationContent.body.iconsList.map((item) => (
                            <b2x.Col key={item.contentSectionId} size={{ md: 3, xs: 6 }}>
                              <div className="py-1 py-lg-3 text-center">
                                {isIconName(item.name) && (
                                  <>
                                    <span className="services-icons">
                                      <Icon className="service-icon" fontSize={30} name={item.name as IconName} />
                                    </span>
                                    <h6 className="pt-3 small">{item.iconTitle}</h6>
                                  </>
                                )}
                              </div>
                            </b2x.Col>
                          ))}
                        </b2x.Row>
                      </Container>
                    )}
                    <div className="d-grid">
                      <Button label={t('misc.register')} onClick={handleButtonClick} variant="primary" />
                    </div>
                  </div>
                  <div className="h5 text-center fw-bold py-4">
                    {b2x.formatHtml(whoisContent?.body.continueAsGuest?.subTitle)}
                  </div>
                  <b2x.CartEmailFormHelper variant="whois">
                    {({ fieldsHelper, formik }) => (
                      <>
                        <b2x.FormGroup {...fieldsHelper.email.formGroup} label={undefined}>
                          <b2x.TextInput {...fieldsHelper.email.textInput} />
                        </b2x.FormGroup>
                        <b2x.FormGroup {...fieldsHelper.privacy.formGroup}>
                          <b2x.Checkbox className="extra-small" {...fieldsHelper.privacy.checkbox} />
                        </b2x.FormGroup>
                        <div className="d-grid">
                          <b2x.Button {...fieldsHelper.buttons.submit} variant="outline-primary" />
                        </div>
                      </>
                    )}
                  </b2x.CartEmailFormHelper>
                </>
              )}
              {register && (
                <div className="mb-4">
                  <h3 className="text-center text-uppercase fw-medium mb-3">
                    {b2x.formatHtml(whoisContent?.body.continueAsGuest?.title)}
                  </h3>
                  <b2x.CustomerForm onCancel={handleCancelButtonClick} onSuccess={handleRegistrationSuccess} />
                </div>
              )}
            </Box>
          </b2x.Col>
        </b2x.Row>
      </Container>
    </Page>
  );
};
