
import { CustomerPopulate } from './customer';
import {  SurveyAnswerQuestionRequestApiDto, SurveyApiDto, SurveyResultApiDto,SurveySessionApiDto,SurveySessionInitRequestApiDto } from './dto';
import { apiRequest, BaseOptions, getBaseOptions } from './utils/util';

const rootPath = '/v2/survey';

export interface SurveyPopulate {
  details?: boolean;
}

export interface SurveySessionPopulate {
  answeredQuestions?: boolean;
  customer?: CustomerPopulate;
  nextQuestion?: boolean;
  survey?: SurveyPopulate;
}

export interface GetSurveySessionOptions extends BaseOptions {
  code?: string;
  id?: string;
  populate?: SurveySessionPopulate;
}

export interface GetSurveyInfoOptions extends BaseOptions {
  code?: string;
  populate?: SurveyPopulate;
}

export interface GetSurveyResultOptions extends BaseOptions {
  id?: string;
}

export const getSurveySession = async (options?: GetSurveySessionOptions) => 
  apiRequest<SurveySessionApiDto, void, SurveySessionPopulate>({
    ...getBaseOptions(options),
    method: 'GET',
    params: {
      code: options?.code,
      id: options?.id
    },
    populate: options?.populate,
    url: `${rootPath}/session`,
  });

export const initSurveySession = async (data: SurveySessionInitRequestApiDto, options?: BaseOptions) => 
  apiRequest<string, SurveySessionInitRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${rootPath}/session`,
  });

export const getSurveyInfo = async (options?: GetSurveyInfoOptions) => 
  apiRequest<SurveyApiDto, void, SurveyPopulate>({
    ...getBaseOptions(options),
    method: 'GET',
    params: {
      code: options?.code
    },
    populate: options?.populate,
    url: `${rootPath}/info`,
  });

export const setSurveySessionAnswer = async (data: SurveyAnswerQuestionRequestApiDto,  options?: BaseOptions) => 
  apiRequest<void, SurveyAnswerQuestionRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${rootPath}/session/answer`,
  });

export const getSurveySessionResult = async (options?: GetSurveyResultOptions) => 
  apiRequest<SurveyResultApiDto, void, void>({
    ...getBaseOptions(options),
    method: 'GET',
    params: {
      id: options?.id 
    },
    url: `${rootPath}/session/result`,
  });