import { CostApiDto, PriceApiDto } from '@b2x/storefront-api-js-client/src';
import classnames from 'classnames';
import React from 'react';

import { AlertFromDto } from './AlertFromDto';
import { analytics } from './analytics/analytics';
import { useCartApi } from './api/useCartApi';
import { useAppContext, useAppStaticContext } from './AppContext';
import { Badge } from './Badge';
import { CartSkuProps } from './CartSku';
import { CartSkuBadges } from './CartSkuBadges';
import { Col } from './Col';
import { ConditionalWrapper } from './ConditionalWrapper';
import { appConfig } from './config';
import { CartSkuQuantityFormHelper } from './form/CartSkuQuantityForm';
import { Select } from './form/fields/Select';
import { FormGroup } from './form/FormGroup';
import { Div, H6, Span, Ul } from './HTMLElement';
import { t } from './i18n/i18n';
import { ImageSwitcher } from './ImageSwitcher';
import { OffersList } from './OffersList';
import { Link } from './router/Link';
import { Row } from './Row';
import { SeparatedList } from './SeparatedList';
import { useModals } from './useModals';
import { formatCurrency, renderUI } from './util';

export const CartSkuA = ({
  cartSku,
  compactLayout,
  hideProductName,
  hideSkuName,
  hideVariantAttributes,
  index,
  readOnly,
  temporaryPropToDisableProductPageLink,
  temporaryPropToDisableWishlistButton,
}: CartSkuProps) => {
  const { moveToWishlist, removeSku } = useCartApi();
  const { session } = useAppContext();

  const handleRemoveButtonClick = React.useCallback(() => {
    removeSku({ cartSku });
  }, [cartSku, removeSku]);

  const { onAddToWishlistAsGuestButtonClick } = useAppStaticContext();

  const handleMoveToWishlistButtonClick = React.useCallback(() => {
    if (session?.userLogged) {
      moveToWishlist({ idCartSku: cartSku.id }).then((response) => {
        // Lancio evento analytics
        cartSku.sku?.product && analytics.events.addToWishlist(response.eventId, cartSku.sku.product, cartSku.sku);
      });
    } else {
      onAddToWishlistAsGuestButtonClick && onAddToWishlistAsGuestButtonClick();
    }
  }, [session?.userLogged, moveToWishlist, cartSku.id, cartSku.sku, onAddToWishlistAsGuestButtonClick]);

  const wishlistEnabled = React.useMemo(
    () => appConfig.enableWishlist && !temporaryPropToDisableWishlistButton,
    [temporaryPropToDisableWishlistButton]
  );

  const productPageLinkEnabled = React.useMemo(
    () => !readOnly && !temporaryPropToDisableProductPageLink,
    [readOnly, temporaryPropToDisableProductPageLink]
  );

  return renderUI({
    bs5: (
      <Div className="cart-sku">
        <Row gap={0} key={cartSku.id} uiClassName={{ bs5: classnames('py-3', { 'border-top': index > 0 }) }}>
          <Col size={{ xs: 12 }} uiClassName={{ bs5: 'col-md' }}>
            <Div className="cart-sku-info" dynamicPadding={!compactLayout ? { end: 3 } : undefined}>
              <Row>
                {cartSku.sku?.product && cartSku.sku.image?.src && (
                  <Col size={'auto'}>
                    <ConditionalWrapper
                      condition={productPageLinkEnabled}
                      wrapper={<Link closeModal to={cartSku.sku.product.url} />}
                    >
                      <Div style={{ width: 90 }}>
                        <ImageSwitcher
                          aspectRatio={appConfig.productImageAspectRatio}
                          fluid
                          format={288}
                          src={cartSku.sku.image.src}
                        />
                      </Div>
                    </ConditionalWrapper>
                  </Col>
                )}
                <Col>
                  <Div dynamicPadding={{ top: 3 }}>
                    {cartSku.sku?.product && (
                      <ConditionalWrapper
                        condition={productPageLinkEnabled}
                        wrapper={
                          <Link className="text-reset text-decoration-none" closeModal to={cartSku.sku.product.url} />
                        }
                      >
                        <Div dynamicMargin={{ bottom: 2 }}>
                          {!hideProductName && (
                            <H6 className="product-name" dynamicMargin={{ bottom: 0 }}>
                              {cartSku.sku.product.name}
                            </H6>
                          )}
                          {!hideSkuName && cartSku.sku.product.name !== cartSku.sku.name && (
                            <H6 className="product-sku-name" dynamicMargin={{ bottom: 0 }}>
                              {cartSku.sku.name}
                            </H6>
                          )}
                        </Div>
                      </ConditionalWrapper>
                    )}
                    {cartSku.assembledComponents && cartSku.assembledComponents.length > 0 && (
                      <>
                        <strong className="small">{t('cart.productTile.composedOf')}</strong>
                        <Ul
                          className="assembled-component-product-list"
                          margin={0}
                          padding={0}
                          style={{ listStyle: 'none' }}
                        >
                          {cartSku.assembledComponents.map((componentSku) => (
                            <li className="small" key={componentSku.id}>
                              {`${componentSku.quantity}x - ${componentSku.sku?.product?.name} ${componentSku.sku?.name}`}
                              {componentSku.alerts !== undefined && componentSku.alerts.length > 0 && (
                                <Div marginTop={2}>
                                  {componentSku.alerts.map((alert) => (
                                    <AlertFromDto alert={alert} key={alert.code} light noMargin small />
                                  ))}
                                </Div>
                              )}
                            </li>
                          ))}
                        </Ul>
                      </>
                    )}
                    <Ul
                      dynamicMargin={{ bottom: 0 }}
                      dynamicPadding={{ xs: 0 }}
                      style={{ listStyle: 'none' }}
                      uiClassName={{ bs5: 'text-dark' }}
                    >
                      {cartSku.sku?.product?.giftCard ? (
                        <>
                          {cartSku.sku.attributesMap && (
                            <li>
                              {t('misc.giftCard.value')}{' '}
                              {formatCurrency(parseInt(cartSku.sku.attributesMap['Gift_card_valore']?.value ?? '0'))}
                            </li>
                          )}
                          <li>
                            {t('misc.giftCard.sender.label')} {cartSku.giftCard?.senderName}
                          </li>
                          <li>
                            {t('misc.giftCard.receiver.label')} {cartSku.giftCard?.receiverName}
                          </li>
                          <li>
                            {t('misc.giftCard.receiver.mail')} {cartSku.giftCard?.receiverEmail}
                          </li>
                          {cartSku.giftCard?.message && (
                            <li>
                              <GiftCardMessage message={cartSku.giftCard.message} />
                            </li>
                          )}
                        </>
                      ) : (
                        <>
                          {!hideVariantAttributes &&
                            cartSku.sku?.attributes
                              ?.filter((attribute) => attribute.variant === true)
                              .map((attribute) => (
                                <li key={attribute.typeCode}>
                                  <small>
                                    {attribute.title}: {attribute.value}
                                  </small>
                                </li>
                              ))}
                          {!hideVariantAttributes &&
                            appConfig.productVariantAttributesCodes?.map(
                              (attributeCode) =>
                                cartSku.sku?.product?.attributes?.find(
                                  (attribute) => attribute.typeCode === attributeCode
                                ) && (
                                  <li key={attributeCode}>
                                    <small>
                                      {
                                        cartSku.sku.product.attributes.find(
                                          (attribute) => attribute.typeCode === attributeCode
                                        )?.title
                                      }
                                      :{' '}
                                      {
                                        cartSku.sku.product.attributes.find(
                                          (attribute) => attribute.typeCode === attributeCode
                                        )?.value
                                      }
                                    </small>
                                  </li>
                                )
                            )}
                          {compactLayout && (
                            <li>
                              <small>
                                {t('cart.quantity')}: {cartSku.quantity}
                              </small>
                            </li>
                          )}
                        </>
                      )}
                    </Ul>
                    {compactLayout && cartSku.price !== undefined && cartSku.cost !== undefined && (
                      <ItemCostBlock cost={cartSku.cost} price={cartSku.price} quantity={cartSku.quantity} />
                    )}
                    {cartSku.offers && <OffersList offers={cartSku.offers.filter((offer) => offer.showOnCartSku)} />}
                  </Div>
                </Col>
              </Row>
            </Div>
          </Col>
          {!compactLayout && (
            <Col size={wishlistEnabled ? { md: 'auto' } : { md: 4, xl: 3, xs: 12 }} style={{ minWidth: '215px' }}>
              <Div
                className="cart-sku-action"
                display={'flex'}
                flexDirection={'column'}
                gap={3}
                justifyContent={'spaceBetween'}
                paddingTop={{ md: 0, xs: 3 }}
                uiClassName={{ bs5: 'h-100' }}
              >
                <Div className={classnames({ 'pt-3': readOnly })}>
                  <Row>
                    {!cartSku.sku?.product?.giftCard && (
                      <Col size="auto">
                        {!readOnly ? (
                          <CartSkuQuantityFormHelper
                            availability={appConfig.productMaxQuantity}
                            cartSku={cartSku}
                            readonly={readOnly}
                          >
                            {({ fieldsHelper }) => (
                              <FormGroup {...fieldsHelper.quantity.formGroup} label={undefined} noMarginBottom>
                                <Select {...fieldsHelper.quantity.select} />
                              </FormGroup>
                            )}
                          </CartSkuQuantityFormHelper>
                        ) : (
                          <div className="d-flex align-items-center justify-content-end">
                            <span>x{cartSku.quantity}</span>
                          </div>
                        )}
                      </Col>
                    )}
                    {cartSku.price !== undefined && cartSku.cost !== undefined && (
                      <Col className={'d-flex align-items-center justify-content-end'}>
                        <ItemCostBlock cost={cartSku.cost} price={cartSku.price} quantity={cartSku.quantity} />
                      </Col>
                    )}
                  </Row>
                </Div>
                <CartSkuBadges cartSku={cartSku}>
                  {({ cartSkuBadges }) =>
                    cartSkuBadges.length > 1 && (
                      <Div alignItems="end" className="cart-sku-badges" display="flex" flexDirection="column" gap={2}>
                        {cartSkuBadges.map((cartSkuBadge) => (
                          <Badge key={cartSkuBadge.className} {...cartSkuBadge} />
                        ))}
                      </Div>
                    )
                  }
                </CartSkuBadges>
                {!readOnly && (
                  <Div
                    display={'flex'}
                    dynamicMargin={{ top: 2 }}
                    justifyContent={'end'}
                    uiClassName={{ bs5: 'text-dark' }}
                  >
                    <SeparatedList
                      items={[
                        ...(wishlistEnabled
                          ? [{ cta: handleMoveToWishlistButtonClick, label: t('cart.productTile.sendToWishlist') }]
                          : []),
                        ...(cartSku.canRemove
                          ? [{ cta: handleRemoveButtonClick, label: t('cart.productTile.removeItem') }]
                          : []),
                      ]}
                      separator={<Div alignSelf="center" uiClassName={{ bs5: 'vr' }} />}
                      textUnderlined
                    />
                  </Div>
                )}
              </Div>
            </Col>
          )}
          {cartSku.alerts !== undefined && cartSku.alerts.length > 0 && (
            <Col size={12}>
              <Div dynamicMargin={{ top: 2 }}>
                {cartSku.alerts.map((alert) => (
                  <AlertFromDto alert={alert} key={alert.code} light noMargin small />
                ))}
              </Div>
            </Col>
          )}
        </Row>
      </Div>
    ),
  });
};

interface ItemCostBlockProps {
  cost: CostApiDto;
  price: PriceApiDto;
  quantity?: number;
  small?: boolean;
}

const ItemCostBlock = ({ cost, price, quantity = 1, small }: ItemCostBlockProps) => {
  return renderUI({
    bs5: (
      <Div className="cart-sku-price">
        {cost.value > 0 ? (
          <>
            {price.crossedOutValue && (
              <Span dynamicPadding={{ end: 3 }}>
                <del>{formatCurrency(price.crossedOutValue * quantity)}</del>
              </Span>
            )}
            <Span uiClassName={{ bs5: price.crossedOutValue !== undefined ? 'text-offer' : undefined }}>
              <strong>{formatCurrency(cost.value * quantity)}</strong>
            </Span>
          </>
        ) : (
          <strong>{t('misc.giftProduct')}</strong>
        )}
      </Div>
    ),
  });
};

interface GiftCardMessageProps {
  message: string;
}

const GiftCardMessage = ({ message }: GiftCardMessageProps) => {
  const { showModal } = useModals();

  const handleShowMessageButtonClick = React.useCallback(
    () =>
      showModal({
        children: message,
        title: 'Il tuo messaggio',
      }),
    [message, showModal]
  );

  return (
    <span
      className="gift-card-show-message"
      onClick={handleShowMessageButtonClick}
      onKeyPress={handleShowMessageButtonClick}
      role={'button'}
      tabIndex={0}
    >
      {t('misc.giftCard.message')}
    </span>
  );
};
