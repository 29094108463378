import './css/style.scss';
import './i18n/i18n';

import { b2x } from '@b2x/react/src';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App';
import { Button, ButtonVariant } from './Button';
import { CartSku } from './CartSku';
import { Container } from './Container';
import { en } from './i18n/locales/en';
import { it } from './i18n/locales/it';
import { IconName, IconSize } from './Icon';
import { OffcanvasHeader } from './Offcanvas';
import { ThemeColor } from './pages/TemplatePage';
import { ProductTile, ProductTileBasicInfo, ProductTileExtraInfo } from './ProductTile';
import { reportWebVitals } from './reportWebVitals';
import { ServiceContactsSubpage } from './ServiceContactsSubpage';

b2x.init<ThemeColor, ButtonVariant, IconName, IconSize>({
  analytics: {
    googleAnalytics4: {
      debug: true,
      modifyItem: (item, product) => ({
        ...item,
        availability: product.sku.state,
        full_price: product.sku.price.value + (product.sku.discount ?? 0),
      }),
    },
    googleTagManager: {
      debug: true,
    },
  },
  api: {
    apiKey: 'GIJEHACAABHCCCEIHEDCGC',
    productPopulate: {
      alternativeImages: true,
      attributes: true,
      brand: true,
      priceRange: true,
      reviews: true,
      skus: {
        alternativeImages: true,
        attributes: true,
        price: true,
      },
      variantProducts: {
        attributes: true,
      },
    },
    productTilePopulate: {
      alternativeImages: true,
      attributes: true,
      brand: true,
      priceRange: true,
      skus: {
        alternativeImages: true,
        attributes: true,
        price: true,
      },
      variantProducts: {
        attributes: true,
      },
    },
    sessionOptions: {
      populate: {
        cart: {
          packs: {
            cartSkus: {
              giftCard: true,
              sku: {
                product: true,
              },
            },
          },
          shippingProfile: true,
        },
        customer: true,
        wishlist: { products: true, skus: { product: true } },
      },
    },
  },
  cookiebot: {
    id: '5966a3a0-09b0-41df-9a98-c7be5cd46516',
  },
  defaultButtonVariant: 'primary',
  enableProductAvailabilityEmailNotification: true,
  enableWishlist: true,
  form: {
    buttons: {
      cancel: {
        defaultVariant: 'outline-primary',
      },
      submit: {
        defaultVariant: 'primary',
      },
    },
  },
  i18n: {
    en: en,
    it: it,
  },
  icons: {
    arrow: {
      bottom: 'arrow-down-bold',
      left: 'arrow-left-bold',
      right: 'arrow-right-bold',
      top: 'arrow-up-bold',
    },
    cart: 'shopping-cart',
    close: 'close',
    configurator: 'settings',
    download: 'download',
    info: 'info',
    logout: 'logout',
    passwordToggle: {
      hidden: 'hidden',
      visible: 'visible',
    },
    shipping: 'delivery',
    slider: {
      arrow: {
        left: 'arrow-left-light',
        right: 'arrow-right-light',
      },
    },
  },
  installmentPaymentBanner: {
    paypal: {
      enabled: true,
      layout: 'text',
    },
  },
  paypal: {
    merchantClientId: 'AS-zz8zfHI-6-jFIsCMZd2J5R_jO4U9qywhakSJWGiDVuFhlWwgB1UKv1lRSdg0k25CXwt481-mbbp1y',
  },
  preRelease: true,
  preselectFirstAvailableSku: true,
  productImageAspectRatio: 4 / 5,
  projectName: 'bionike',
  ui: 'bs5',
  variants: {
    AccountHomePage: 'A',
    AccountOffcanvas: 'A',
    AddressFieldset: 'A',
    Button: Button,
    CartAddressFromAddressBookForm: 'A',
    CartAddressesForm: 'A',
    CartPage: 'B',
    CartSku: CartSku,
    ChangePasswordForm: 'A',
    Container: Container,
    CustomerAddressForm: 'A',
    CustomerForm: 'B',
    GiftCardForm: 'A',
    HeaderCheckout: 'A',
    InvoiceFieldset: 'A',
    LocaleForm: 'A',
    LoginForm: 'A',
    OffcanvasHeader: OffcanvasHeader,
    OrderDetails: 'A',
    OrderGuestForm: 'A',
    ProductTile: ProductTile,
    ProductTileBasicInfo: ProductTileBasicInfo,
    ProductTileExtraInfo: ProductTileExtraInfo,
    SearchFilters: 'B',
    ServiceContactsSubpage: ServiceContactsSubpage,
    SimpleSearchForm: 'A',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <b2x.router.BrowserRouter>
      <b2x.ModalsContext>
        <App />
      </b2x.ModalsContext>
    </b2x.router.BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
