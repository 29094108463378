import {
  SurveyApiDto,
  SurveyQuestionApiDto,
  SurveyResultApiDto,
  SurveySessionApiDto,
} from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { useSurveyApi } from './api/useSurveyApi';

export interface UseSurveyProps {
  surveyCode: string; // Identificativo del sondaggio ????
}

export const useSurvey = (surveyCode: string) => {
  const { getSurveyInfo, getSurveySession, getSurveySessionResult, initSurveySession, setSurveySessionAnswer } =
    useSurveyApi();

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Stato del sondaggio

  const [init, setInit] = React.useState<boolean>(false);
  const [surveyInfo, setSurveyInfo] = React.useState<SurveyApiDto>();
  const [surveySession, setSurveySession] = React.useState<SurveySessionApiDto>();
  const [surveyStep, setSurveyStep] = React.useState<SurveyQuestionApiDto | undefined>();
  const [result, setResult] = React.useState<SurveyResultApiDto>();

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Recupero le informazioni generiche del sondaggio

  const getInfo = React.useCallback(() => {
    console.log('get info callback');

    getSurveyInfo()
      .then((response) => {
        console.log('Info recuperate correttamente');
        console.log(response);
        setSurveyInfo(response.data);
      })
      .catch((response) => {
        console.log('Si è verificato un problema con la getSurveyInfo');
        console.log(response);
      });
  }, [getSurveyInfo]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Recupero le informazioni del sondaggio (domanda, risposte, ...)

  const getSurvey = React.useCallback(() => {
    console.log('get survey');
    console.log(`surveyCode: ${surveyCode}`);

    getSurveySession({ code: surveyCode })
      .then((response) => {
        console.log('Sondaggio recuperato correttamente');
        console.log(response);
        setSurveySession(response.data);
        setSurveyStep(response.data.nextQuestion);
      })
      .catch((response) => {
        console.log('Si è verificato un problema con la setSurveySessionAnswer');
        console.log(response);
      });
  }, [getSurveySession, surveyCode]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Invio la risposta scelta dall'utente

  const sendAnswer = React.useCallback(
    (idQuestion: number, idResponse: number, idSurveyCustomer?: number) => {
      console.log('send answer');

      setSurveySessionAnswer({ idQuestion: idQuestion, idResponse: idResponse, idSurveyCustomer: idSurveyCustomer })
        .then((response) => {
          console.log('Risposta inviata correttamente');
          console.log(response);
          getSurvey();
        })
        .catch((response) => {
          console.log('Si è verificato un problema con la setSurveyCustomerAnswer');
          console.log(response);
        });
    },
    [getSurvey, setSurveySessionAnswer]
  );

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Recupero l'esito del sondaggio

  const getResult = React.useCallback(() => {
    getSurveySessionResult()
      .then((response) => {
        console.log('Risultato recuperato correttamente');
        setResult(response.data);
      })
      .catch((response) => {
        console.log('Si è verificato un problema con la getSurveySessionResult');
        console.log(response);
      });

    console.log('show result');
  }, [getSurveySessionResult]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Inizializzazione del sondaggio

  React.useEffect(() => {
    if (!init) {
      console.log(`surveyCode: ${surveyCode}`);

      initSurveySession({ forceNew: true, surveyCode: surveyCode })
        .then(() => {
          setInit(true);
          getInfo();
          getSurvey();
        })
        .catch(() => {
          console.log('errore durante la fase di inizializzazione del survey');
        });
    }
  }, [getInfo, getSurvey, init, initSurveySession, surveyCode]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Callback per andare avanti nel sondaggio

  const onNextQuestionButtonClick = React.useCallback(
    (idQuestion: number, idResponse: number, idSurveyCustomer?: number) => {
      console.log('next button click');
      if (surveySession?.nextQuestion) {
        // C'è un'altro step
        sendAnswer(idQuestion, idResponse, idSurveyCustomer);
      } else {
        // Lanciare la get result perchè non ci sono piu step
        getResult();
      }
    },
    [getResult, sendAnswer, surveySession?.nextQuestion]
  );

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Callback per tornare indietro nel sondaggio

  const onPrevQuestionButtonClick = React.useCallback(() => {
    // Quando clicco il tasto indietro, carico sulla struttura dati i valori della risposta precedente
    setSurveyStep(surveySession?.answeredQuestions?.at(-1));
    console.log('prev button click');
  }, [surveySession?.answeredQuestions]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Callback per resettare il sondaggio

  const onResetButtonClick = React.useCallback(() => {
    setInit(false);
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Return del custom hook

  return {
    init,
    onNextQuestionButtonClick,
    onPrevQuestionButtonClick,
    onResetButtonClick,
    result,
    sendAnswer,
    surveyInfo,
    surveyStep,
  };
};
