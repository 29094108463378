import './ProductStickyFooterD.scss';

import classnames from 'classnames';
import React from 'react';

import { AddToCartFormButton } from './AddToCartFormButton';
import { BestPrice } from './BestPrice';
import { Button } from './Button';
import { Col } from './Col';
import { appConfig } from './config';
import { Container } from './Container';
import { Div, P } from './HTMLElement';
import { t } from './i18n/i18n';
import { ImageSwitcher } from './ImageSwitcher';
import { PriceBlock } from './PriceBlock';
import { ProductStickyFooterProps } from './ProductStickyFooter';
import { Row } from './Row';
import { useBreakpoint } from './useBreakpoint';
import { UsePriceResult } from './usePrice';
import { useWindowScrollPosition } from './useWindowScrollPosition';
import { useWindowSize } from './useWindowSize';
import { untilBreakpoint } from './util';

export const ProductStickyFooterD = <ButtonVariant extends string, IconName extends string, IconSize extends number>({
  className,
  fieldsHelper,
  formik,
  offsetBottom = 100,
  offsetTop = 100,
  priceHelper,
  product,
  selectedProductImage,
  selectedSku,
  submitDesktopButton,
  submitMobileButton,
}: ProductStickyFooterProps<ButtonVariant, IconName, IconSize>) => {
  const [show, setShow] = React.useState<boolean>(false);

  const scrollPosition = useWindowScrollPosition();
  const windowSize = useWindowSize();
  const body = document.body;
  const html = document.documentElement;
  const documentHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );

  React.useEffect(() => {
    const scrollTopPosition = scrollPosition.position;
    const scrollBottomPosition = scrollPosition.position + windowSize.height;

    scrollTopPosition >= offsetTop && scrollBottomPosition <= documentHeight - offsetBottom
      ? setShow(true)
      : setShow(false);
  }, [documentHeight, offsetBottom, offsetTop, scrollPosition.position, windowSize.height]);

  const currentBreakpoint = useBreakpoint();

  return (
    <Div
      className={classnames('product-sticky-footer', className, {
        show: show,
      })}
      style={{ position: 'fixed', width: '100%', zIndex: 1000 }}
      uiClassName={{ bs5: 'shadow-lg' }}
    >
      <Div className="product-sticky-footer-mobile" display={{ lg: 'none' }} uiClassName={{ bs5: 'extra-small' }}>
        <Row gap={0} style={{ alignItems: 'center' }}>
          {selectedSku && (
            <Col size={12}>
              <Div paddingX={3}>
                <Row>
                  <Col size={6} uiClassName={{ bs5: 'border-end' }}>
                    <Div display={'flex'} justifyContent={'spaceBetween'} marginY={1} paddingY={3}>
                      <Div>{t('product.size')}</Div>
                      <Div>
                        <b>{selectedSku.name}</b>
                      </Div>
                    </Div>
                  </Col>
                  <Col size={6}>
                    <Div
                      alignItems={'center'}
                      display={'flex'}
                      justifyContent={'spaceBetween'}
                      marginY={1}
                      paddingY={3}
                    >
                      <Div>{t('form.addToCartForm.fields.quantity.label')}</Div>
                      {selectedSku.state === 'AVAILABLE' && (
                        <Div alignItems={'center'} display={'flex'} gap={3}>
                          <Button
                            variant="blank"
                            {...fieldsHelper.buttons.quantity.decrease}
                            iconStart={{ name: appConfig.icons?.decrement || '', size: 12 }}
                            label={undefined}
                          />
                          <P className="product-quantity" fontWeight={700} margin={0}>
                            {formik.values.quantity}
                          </P>
                          <Button
                            variant="blank"
                            {...fieldsHelper.buttons.quantity.increase}
                            iconStart={{ name: appConfig.icons?.increment || '', size: 12 }}
                            label={undefined}
                          />
                        </Div>
                      )}
                    </Div>
                  </Col>
                </Row>
              </Div>
              <hr style={{ margin: '0' }} />
            </Col>
          )}
          <Col size={''}>
            <Div paddingX={3}>
              <ProductPrice priceHelper={priceHelper} />
            </Div>
          </Col>
          <Col size={'auto'}>
            <Div className="add-to-cart-button" paddingX={3} paddingY={2}>
              <AddToCartFormButton
                availabilityEmailNotificationButton={{
                  label: untilBreakpoint('xs', currentBreakpoint)
                    ? t('form.addToCartForm.buttons.availabilityEmailNotification.addShort.label')
                    : t('form.addToCartForm.buttons.availabilityEmailNotification.addLong.label'),
                }}
                fieldsHelper={fieldsHelper}
                selectedSku={selectedSku}
                submitButton={submitMobileButton}
              />
            </Div>
          </Col>
        </Row>
      </Div>
      <Div
        className="product-sticky-footer-desktop"
        display={{ lg: 'block', xs: 'none' }}
        dynamicPadding={{ vertical: 2 }}
      >
        <Container>
          <Row>
            <Col size={''}>
              <Div alignItems={'center'} display={'flex'}>
                <Div className="product-image" display={'inline-block'} marginEnd={3}>
                  <ImageSwitcher
                    aspectRatio={appConfig.productImageAspectRatio}
                    fluid
                    src={selectedProductImage?.src}
                    style={{ maxHeight: '50px' }}
                  />
                </Div>
                <Div
                  className="product-name"
                  dynamicPadding={{ end: 4 }}
                  fontWeight={700}
                  style={{ maxWidth: '260px' }}
                >
                  {product.name}
                </Div>
                {selectedSku && (
                  <Div alignItems={'center'} className="product-info" display={'flex'}>
                    <P margin={0} style={{ fontWeight: 300 }}>
                      {selectedSku.name}
                    </P>
                  </Div>
                )}
              </Div>
            </Col>
            <Col size={'auto'} style={{ alignItems: 'center', display: 'flex' }}>
              <Div alignItems={'center'} display={'flex'} gap={5}>
                {selectedSku?.state === 'AVAILABLE' && (
                  <Div alignItems={'center'} display={'flex'} gap={3}>
                    <Button
                      variant="blank"
                      {...fieldsHelper.buttons.quantity.decrease}
                      iconStart={{ name: appConfig.icons?.decrement || '', size: 12 }}
                      label={undefined}
                    />
                    <P className="product-quantity" fontWeight={700} margin={0}>
                      {formik.values.quantity}
                    </P>
                    <Button
                      variant="blank"
                      {...fieldsHelper.buttons.quantity.increase}
                      iconStart={{ name: appConfig.icons?.increment || '', size: 12 }}
                      label={undefined}
                    />
                  </Div>
                )}
                <Div>
                  <ProductPrice priceHelper={priceHelper} />
                </Div>
                <div className="add-to-cart-button">
                  <AddToCartFormButton
                    fieldsHelper={fieldsHelper}
                    selectedSku={selectedSku}
                    submitButton={submitDesktopButton}
                  />
                </div>
              </Div>
            </Col>
          </Row>
        </Container>
      </Div>
    </Div>
  );
};

interface ProductPriceProps {
  priceHelper: UsePriceResult;
}

const ProductPrice = ({ priceHelper }: ProductPriceProps) => {
  return (
    <Div className="product-price-container" display="flex" flexDirection="column" gap={1}>
      <PriceBlock alignItems="center" gap={2} hiddenDiscountLabel hiddenDiscountPercentage priceHelper={priceHelper} />
      <Div uiClassName={{ bs5: 'extra-small' }}>
        <BestPrice priceHelper={priceHelper} />
      </Div>
    </Div>
  );
};
