import { PaymentMethodType } from '@b2x/storefront-api-js-client/src/dto';

import { appConfig } from '../config';
import { env } from '../env';
import { addScript, documentHeadHasScript, slugify } from '../util';
import { AnalyticsEventsProps, InitCommonProps, ProductSku, ProductSkus, ProductSkuWithQuantity } from './analytics';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/ban-types
    dataLayer: Array<string | Object>;
  }
}

let initialized = false;
let debug = false;

interface InitProps extends InitCommonProps {
  configuration?: Configuration;
  id?: string;
}
export interface GoogleAnalytics4Props extends InitProps {
  modifyItem?(item: Item, product: ProductSku): Item;
}

interface Configuration {
  allow_ad_personalization_signals?: boolean;
  allow_google_signals?: boolean;
  campaign?: {
    campaign_campaign_name?: string;
    campaign_content?: string;
    campaign_id: string;
    campaign_medium?: string;
    campaign_source?: string;
    term?: string;
  };
  campaign_content?: string;
  campaign_id?: string;
  campaign_medium?: string;
  campaign_name?: string;
  campaign_source?: string;
  campaign_term?: string;
  client_id?: string;
  content_group?: string;
  cookie_domain?: string;
  cookie_expires?: number;
  cookie_flags?: string;
  cookie_path?: string;
  cookie_prefix?: string;
  cookie_update?: boolean;
  language?: string;
  page_location?: string;
  page_referrer?: string;
  page_title?: string;
  screen_resolution?: string;
  send_page_view?: boolean;
  user_id?: string;
  user_properties?: object;
}

const init = (props?: InitProps): void => {
  const id = props?.id ?? env.REACT_APP_GA4_ID;
  debug = props?.debug || false;
  if (id) {
    if (!initialized) {
      initialized = true;
      install(id);
      Object.entries(props?.configuration ?? {}).forEach(([key, value]) => {
        gtag('set', key, value);
      });
    }
  }
};

export type Event =
  | 'add_payment_info' //TODO
  | 'add_shipping_info' //TODO
  | 'add_to_cart'
  | 'add_to_wishlist'
  | 'begin_checkout'
  | 'earn_virtual_currency' //TODO
  | 'generate_lead'
  | 'join_group' //TODO
  | 'level_end' //TODO
  | 'level_start' //TODO
  | 'level_up' //TODO
  | 'login'
  | 'post_score' //TODO
  | 'purchase'
  | 'refund' //TODO
  | 'remove_from_cart'
  | 'search'
  | 'select_content' //TODO
  | 'select_item' //TODO
  | 'select_promotion' //TODO
  | 'share' //TODO
  | 'sign_up'
  | 'spend_virtual_currency' //TODO
  | 'tutorial_begin' //TODO
  | 'tutorial_complete' //TODO
  | 'unlock_achievement' //TODO
  | 'view_cart' //TODO
  | 'view_item'
  | 'view_item_list'
  | 'view_promotion'; //TODO

export interface EventParameters {
  affiliation?: string;
  checkout_option?: PaymentMethodType;
  checkout_step?: number;
  coupon?: string;
  currency?: string;
  description?: string;
  fatal?: boolean;
  item_list_id?: string;
  item_list_name?: string;
  items?: Array<Item>;
  method?: string;
  page_location?: string;
  page_path?: string;
  page_title?: string;
  payment_type?: string;
  search_term?: string;
  shipping?: number;
  shipping_tier?: string;
  tax?: number;
  transaction_id?: string;
  value?: number;
}

export interface Item {
  affiliation?: string;
  coupon?: string;
  discount?: number;
  index?: number;
  item_brand?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_id: string;
  item_list_id?: string;
  item_list_name?: string;
  item_name?: string;
  item_variant?: string;
  location_id?: string;
  price?: number;
  quantity?: number;
}

const install = (id: string) => {
  // id = environment === 'PRODUCTION' ? id : 'G-PPXT5LH0VR';
  if (!documentHeadHasScript('gtag')) {
    const queryParams = env.REACT_APP_GA4_QUERY_PARAMS;

    addScript({
      async: true,
      id: 'gtag',
      src: `https://www.googletagmanager.com/gtag/js?id=${id}${queryParams}`,
    });
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    window.dataLayer = window.dataLayer || [];

    // Inizio gestione valori di default per Google Consent Mode v2
    if (appConfig.consentMode) {
      gtag('consent', 'default', {
        ad_personalization: 'denied',
        ad_storage: 'denied',
        ad_user_data: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'granted',
        wait_for_update: 500,
      });
      gtag('set', 'ads_data_redaction', true);
      gtag('set', 'url_passthrough', true);
    }
    // Fine gestione valori di default per Google consent Mode v2

    gtag('js', new Date());
  }
  gtag('config', id);
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions, func-style
function gtag(...args: Array<unknown>) {
  if (initialized) {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
    log(args);
  } else {
    log('Blocked because not initialized.', args);
  }
}

// const config = (
//   id: string
//   // params?: { page_location?: string; page_path?: string; page_title?: string; send_page_view?: boolean }
// ) => {
//   gtag('config', id);
// };

const sendEvent = (event: Event, parameters?: EventParameters) => {
  gtag('event', event, parameters);
};

export const fromProductSkuToItem = (product: ProductSku): Item => {
  const last5Categories = product.categories?.slice(-5);

  let item: Item = {
    // affiliation: '',
    // coupon: '', // TODO
    discount: product.sku.discount,
    // full_price: product.sku.price.crossedOutValue, // custom bionike
    index: product.listing?.index,
    item_brand: product.brand.name,
    item_category: last5Categories?.at(0)?.name,
    item_category2: last5Categories?.at(1)?.name,
    item_category3: last5Categories?.at(2)?.name,
    item_category4: last5Categories?.at(3)?.name,
    item_category5: last5Categories?.at(4)?.name,
    item_id: product.sku.id,
    item_list_id: product.listing?.name ? slugify(product.listing.name) : undefined,
    item_list_name: product.listing?.name,
    item_name: product.name,
    item_variant: product.sku.name,
    price: product.sku.price.value,
    // location_id: '',
  };

  if (appConfig.analytics?.googleAnalytics4?.modifyItem) {
    item = appConfig.analytics.googleAnalytics4.modifyItem(item, product);
  }

  return item;
};

export const fromProductSkuWithQuantityToItem = (product: ProductSkuWithQuantity): Item => {
  return {
    ...fromProductSkuToItem(product),
    quantity: product.quantity,
  };
};

export const fromProductSkusToItems = (product: ProductSkus): Array<Item> => {
  const last5Categories = product.categories?.slice(-5);
  return product.skus.map((sku, index) => ({
    // affiliation: '',
    // coupon: '',
    discount: sku.discount,
    // full_price: sku.price.crossedOutValue, // custom bionike
    index: product.listing?.index,
    item_brand: product.brand.name,
    item_category: last5Categories?.at(0)?.name,
    item_category2: last5Categories?.at(1)?.name,
    item_category3: last5Categories?.at(2)?.name,
    item_category4: last5Categories?.at(3)?.name,
    item_category5: last5Categories?.at(4)?.name,
    item_id: sku.id,
    item_list_id: product.listing?.name ? slugify(product.listing.name) : undefined,
    item_list_name: product.listing?.name,
    item_name: product.name,
    item_variant: sku.name,
    price: sku.price.value,
    // location_id: '',
  }));
};

export const fromProductsSkusToItems = (products: Array<ProductSkus>): Array<Item> => {
  return products.reduce<Array<Item>>((accumulator, product) => {
    const last5Categories = product.categories?.slice(-5);
    return accumulator.concat(
      product.skus.map((sku, index) => ({
        // affiliation: '',
        // coupon: '',
        discount: sku.discount,
        // full_price: sku.price.crossedOutValue,
        index: product.listing?.index,
        item_brand: product.brand.name,
        item_category: last5Categories?.at(0)?.name,
        item_category2: last5Categories?.at(1)?.name,
        item_category3: last5Categories?.at(2)?.name,
        item_category4: last5Categories?.at(3)?.name,
        item_category5: last5Categories?.at(4)?.name,
        item_id: sku.id,
        item_list_id: product.listing?.name ? slugify(product.listing.name) : undefined,
        item_list_name: product.listing?.name,
        item_name: product.name,
        item_variant: sku.name,
        price: sku.price.value,
        // location_id: '',
      }))
    );
  }, []);
};

const log = (message?: unknown, ...optionalParams: Array<unknown>) => {
  if (debug) {
    console.log('analytics', 'ga4:', message, ...optionalParams);
  }
};

export const googleAnalytics4: {
  events: AnalyticsEventsProps;
  init(props?: InitProps): void;
} = {
  events: {
    addPaymentInfo: (props) => {
      sendEvent('add_payment_info', {
        coupon: props.coupon?.code,
        currency: 'EUR',
        items: props.products.map((product) => fromProductSkuWithQuantityToItem(product)),
        payment_type: props.paymentType,
        value: props.totalCost,
      });
    },
    addShippingInfo: (props) => {
      sendEvent('add_shipping_info', {
        coupon: props.coupon?.code,
        currency: 'EUR',
        items: props.products.map((product) => fromProductSkuWithQuantityToItem(product)),
        shipping_tier: props.shippingProfile,
        value: props.totalCost,
      });
    },
    addToCart: (props) => {
      sendEvent('add_to_cart', {
        currency: 'EUR',
        items: props.products.map((product) => fromProductSkuWithQuantityToItem(product)),
        value: props.totalPrice.value,
      });
    },
    addToWishlist: (props) => {
      sendEvent('add_to_wishlist', {
        currency: 'EUR',
        items: [fromProductSkuToItem(props.product)],
        value: props.product.sku.price.value,
      });
    },
    checkoutInit: (props) => {
      sendEvent('begin_checkout', {
        coupon: props.coupon?.code,
        currency: 'EUR',
        items: props.products.map((product) => fromProductSkuWithQuantityToItem(product)),
        value: props.totalCost,
      });
    },
    completeRegistration: (props) => {
      sendEvent('sign_up', { method: props.origin });
    },
    contact: (props) => {
      // Assente in ga4
    },
    customizeProduct: (props) => {
      // Assente in ga4
    },
    donate: (props) => {
      // Assente in ga4
    },
    exception: (props) => {
      // Assente in ga4
    },
    findLocation: (props) => {
      // Assente in ga4
    },
    lead: (props) => {
      sendEvent('generate_lead', {
        // currency: '',
        // value: 0,
      });
    },
    login: (props) => {
      sendEvent('login', {
        method: props.method,
      });
    },
    pageView: (props) => {
      // Assente in ga4
    },
    purchase: (props) => {
      sendEvent('purchase', {
        coupon: props.coupon?.code,
        currency: 'EUR',
        items: props.products.map((product) => fromProductSkuWithQuantityToItem(product)),
        shipping: props.shippingCost,
        tax: props.taxCost,
        transaction_id: props.orderCode,
        value: props.totalCost,
      });
    },
    removeFromCart: (props) => {
      sendEvent('remove_from_cart', {
        currency: 'EUR',
        items: [fromProductSkuWithQuantityToItem(props.product)],
        value: props.product.sku.price.value,
      });
    },
    schedule: () => {
      // Assente in ga4
    },
    search: (props) => {
      sendEvent('search', {
        search_term: props.simpleSearch,
      });
    },
    selectProduct: (props) => {
      sendEvent('select_item', {
        item_list_id: slugify(props.listing.name),
        item_list_name: props.listing.name,
        items: fromProductSkusToItems(props.product),
      });
    },
    startPayment: (props) => {
      // Assente in ga4
    },
    submitApplication: () => {
      // Assente in ga4
    },
    viewCart: (props) => {
      sendEvent('view_cart', {
        currency: 'EUR',
        items: props.products.map((product) => fromProductSkuWithQuantityToItem(product)),
        value: props.totalCost,
      });
    },
    viewProduct: (props) => {
      sendEvent('view_item', {
        currency: 'EUR',
        items: fromProductSkusToItems(props.product),
        value: props.product.fromPrice.value,
      });
    },
    viewProducts: (props) => {
      sendEvent('view_item_list', {
        currency: 'EUR',
        item_list_id: slugify(props.listing.name),
        item_list_name: props.listing.name,
        items: fromProductsSkusToItems(props.products),
      });
    },
  },
  init: (props) => {
    init(props);
  },
};
