/* eslint-disable react/forbid-elements */
import './SurveyPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Button } from '../Button';
import { Container } from '../Container';
import { SurveyAnswerContentType, SurveyPageContentType, SurveyQuestionContentType } from '../contentTypes';
import { HeaderA } from '../pagebuilder/HeaderA';
import { Page } from './Page';

export interface SurveyPageProps {}

export const SurveyPage = (props: SurveyPageProps) => {
  const page = b2x.usePage<SurveyPageContentType>();

  const answers = ['SURVEY_ANSWER_1', 'SURVEY_ANSWER_2', 'SURVEY_ANSWER_3', 'SURVEY_ANSWER_4'];

  const { init } = b2x.useSurvey('SURVEY_CODE');

  return (
    <Page className={classnames('survey-page')} noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      {page?.content?.body.header && <HeaderA mainComponent {...page.content.body.header} />}
      <Container>
        <b2x.Div paddingY={5}>
          {init && 'Sondaggio iniziato'}
          <SurveyQuestion code="SURVEY_QUESTION_1" />
          {answers.length > 0 && (
            <b2x.Div marginBottom={5}>
              <b2x.EqualHeight>
                <b2x.Div display={'flex'} gap={5}>
                  {answers.map((answer) => (
                    <SurveyAnswer code={answer} key={answer} />
                  ))}
                </b2x.Div>
              </b2x.EqualHeight>
            </b2x.Div>
          )}
        </b2x.Div>
      </Container>
      {page?.content?.body.footer?.asset && <b2x.AssetV2 {...page.content.body.footer.asset} />}
    </Page>
  );
};

interface SurveyQuestionProps {
  code: string;
}

const SurveyQuestion = ({ code }: SurveyQuestionProps) => {
  const content = b2x.useContent<SurveyQuestionContentType>(code);

  return (
    <b2x.Div className="survey-question" marginBottom={5}>
      <b2x.Div className="title-container">
        <span className="title">{content?.body.title}</span>
      </b2x.Div>
      <b2x.Div className="content-container">
        <span className="content">{content?.body.content}</span>
      </b2x.Div>
    </b2x.Div>
  );
};

interface SurveyAnswerProps {
  code: string;
}

const SurveyAnswer = ({ code }: SurveyAnswerProps) => {
  const content = b2x.useContent<SurveyAnswerContentType>(code);

  const handleClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    const parentDiv = event.currentTarget.parentNode;

    parentDiv?.querySelectorAll('.survey-answer').forEach((e) => e.classList.remove('active'));

    event.currentTarget.classList.add('active');
  }, []);

  return (
    <Button alignWithFlex={false} className="survey-answer" onClick={handleClick} variant="blank">
      {content?.body.asset && (
        <b2x.Div className="asset-container" marginBottom={4}>
          <b2x.EqualHeightElement name="asset">
            <b2x.AssetV2 className="asset" {...content.body.asset} />
          </b2x.EqualHeightElement>
        </b2x.Div>
      )}
      {content?.body.title && (
        <b2x.Div className="title-container" marginBottom={3}>
          <b2x.EqualHeightElement name="title">
            <span className="title">{content.body.title}</span>
          </b2x.EqualHeightElement>
        </b2x.Div>
      )}
      {content?.body.content && (
        <b2x.Div className="content-container">
          <b2x.EqualHeightElement name="content">
            <span className="content">{content.body.content}</span>
          </b2x.EqualHeightElement>
        </b2x.Div>
      )}
    </Button>
  );
};
