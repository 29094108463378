import './MagazineArticlePage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Container } from '../Container';
import { MagazineArticlePageContentType } from '../contentTypes';
import { AccordionA } from '../pagebuilder/AccordionA';
import { AssetA } from '../pagebuilder/AssetA';
import { CopyA } from '../pagebuilder/CopyA';
import { CopyB } from '../pagebuilder/CopyB';
import { CopyC } from '../pagebuilder/CopyC';
import { CopyD } from '../pagebuilder/CopyD';
import { CopyE } from '../pagebuilder/CopyE';
import { DoubleColumnsA } from '../pagebuilder/DoubleColumnsA';
import { DownloaderA } from '../pagebuilder/DownloaderA';
import { HeaderA } from '../pagebuilder/HeaderA';
import { IconsA } from '../pagebuilder/IconsA';
import { IconsB } from '../pagebuilder/IconsB';
import { IconsC } from '../pagebuilder/IconsC';
import { IconsD } from '../pagebuilder/IconsD';
import { InstagramA } from '../pagebuilder/InstagramA';
import { MultiColumnA } from '../pagebuilder/MultiColumnA';
import { MultiColumnB } from '../pagebuilder/MultiColumnB';
import { ProductsSliderDoubleColumnA } from '../pagebuilder/ProductsSliderDoubleColumnA';
import { ProductsSliderDoubleColumnB } from '../pagebuilder/ProductsSliderDoubleColumnB';
import { ProductsSliderSingleColumnA } from '../pagebuilder/ProductsSliderSingleColumnA';
import { SingleColumnA } from '../pagebuilder/SingleColumnA';
import { SliderA } from '../pagebuilder/SliderA';
import { TabsA } from '../pagebuilder/TabsA';
import { Page } from './Page';

export interface MagazineArticlePageProps {}

export const MagazineArticlePage = (props: MagazineArticlePageProps) => {
  const page = b2x.useContentByPath<MagazineArticlePageContentType>({ populate: { pages: { content: true } } });

  return (
    <Page className="magazine-article-page" noPaddingTop>
      <HeaderA
        className="mb-4"
        copy={{ options: { alignment: 'center', size: 'extra-large' }, title: page?.body.title }}
        options={{ background: '#E8CFC7', enableBreadcrumbs: true }}
      />
      <Container>
        <b2x.Row gapY={{ lg: 5, xs: 3 }} justifyContent={'center'}>
          <b2x.Col className="text-center" size={12}>
            <b2x.ImageFromContentV2 {...page?.body.image} fluid />
          </b2x.Col>
          {page?.body.content && <b2x.Col size={{ lg: 10, xs: 12 }}>{b2x.formatHtml(page.body.content)}</b2x.Col>}
        </b2x.Row>
      </Container>
      <MagazineContent page={page} />
    </Page>
  );
};

interface MagazineContentProps {
  page: b2x.ContentApiDto<MagazineArticlePageContentType> | undefined;
}

const MagazineContent = ({ page }: MagazineContentProps) => {
  const content = page?.body;
  return (
    <React.Fragment>
      {content?.sections?.map((section) => {
        let component = null;
        const sectionComponent = b2x.getItemFromSwitch(section.component);

        if (sectionComponent?.assetA) {
          component = <AssetA {...sectionComponent.assetA} />;
        }

        if (sectionComponent?.accordionA) {
          component = <AccordionA {...sectionComponent.accordionA} />;
        }

        if (sectionComponent?.sliderA) {
          component = <SliderA {...sectionComponent.sliderA} />;
        }

        if (sectionComponent?.productsSliderSingleColumnA) {
          component = <ProductsSliderSingleColumnA {...sectionComponent.productsSliderSingleColumnA} />;
        }

        if (sectionComponent?.productsSliderDoubleColumnA) {
          component = <ProductsSliderDoubleColumnA {...sectionComponent.productsSliderDoubleColumnA} />;
        }

        if (sectionComponent?.productsSliderDoubleColumnB) {
          component = <ProductsSliderDoubleColumnB {...sectionComponent.productsSliderDoubleColumnB} />;
        }

        if (sectionComponent?.copyA) {
          component = <CopyA {...sectionComponent.copyA} />;
        }

        if (sectionComponent?.copyB) {
          component = <CopyB {...sectionComponent.copyB} />;
        }

        if (sectionComponent?.copyC) {
          component = <CopyC {...sectionComponent.copyC} />;
        }

        if (sectionComponent?.copyD) {
          component = <CopyD {...sectionComponent.copyD} />;
        }

        if (sectionComponent?.copyE) {
          component = <CopyE {...sectionComponent.copyE} />;
        }

        if (sectionComponent?.singleColumnA) {
          component = <SingleColumnA {...sectionComponent.singleColumnA} />;
        }

        if (sectionComponent?.doubleColumnsA) {
          component = <DoubleColumnsA {...sectionComponent.doubleColumnsA} />;
        }

        if (sectionComponent?.multiColumnA) {
          component = <MultiColumnA {...sectionComponent.multiColumnA} />;
        }

        if (sectionComponent?.multiColumnB) {
          component = <MultiColumnB {...sectionComponent.multiColumnB} />;
        }

        if (sectionComponent?.iconsA) {
          component = <IconsA {...sectionComponent.iconsA} />;
        }

        if (sectionComponent?.iconsB) {
          component = <IconsB {...sectionComponent.iconsB} />;
        }

        if (sectionComponent?.iconsC) {
          component = <IconsC {...sectionComponent.iconsC} />;
        }

        if (sectionComponent?.iconsD) {
          component = <IconsD {...sectionComponent.iconsD} />;
        }

        if (sectionComponent?.instagramA) {
          component = <InstagramA {...sectionComponent.instagramA} />;
        }

        if (sectionComponent?.headerA) {
          component = <HeaderA {...sectionComponent.headerA} />;
        }

        if (sectionComponent?.downloaderA) {
          component = <DownloaderA {...sectionComponent.downloaderA} />;
        }

        if (sectionComponent?.tabsA) {
          component = <TabsA {...sectionComponent.tabsA} />;
        }

        return (
          <React.Fragment key={section.contentSectionId}>
            {section.options?.showSection && (
              <b2x.Section
                className={classnames('component-section')}
                marginBottom={{
                  lg: section.options.marginBottom?.lg as b2x.MarginSize,
                  md: section.options.marginBottom?.md as b2x.MarginSize,
                  sm: section.options.marginBottom?.sm as b2x.MarginSize,
                  xl: section.options.marginBottom?.xl as b2x.MarginSize,
                  xs: section.options.marginBottom?.xs as b2x.MarginSize,
                  xxl: section.options.marginBottom?.xxl as b2x.MarginSize,
                }}
              >
                {component}
              </b2x.Section>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
