import './RegistrationPage.scss';

import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { RegistrationFormContentType } from '../contentTypes';
import { Icon, IconName, isIconName } from '../Icon';
import { Page } from './Page';

export interface RegistrationPageProps {}

export const RegistrationPage = (props: RegistrationPageProps) => {
  const content = b2x.useContent<RegistrationFormContentType>('REGISTRATION_FORM_CONTENT');

  return (
    <Page className="registration-page" nudeBackground thingsToLoadBeforePageReady={[content]}>
      <Container>
        <b2x.Row className="justify-content-center">
          <b2x.Col size={{ lg: 9, xl: 7 }}>
            <div className="mb-4 text-center">
              <h3 className="text-uppercase fw-medium mb-3">{b2x.formatHtml(content?.body.title)}</h3>
              <p className="fw-normal">{b2x.formatHtml(content?.body.subTitle)}</p>
              {content?.body.iconsList && (
                <Container>
                  <b2x.Row gap={3}>
                    {content.body.iconsList.map((item) => (
                      <b2x.Col key={item.contentSectionId} size={{ md: 3, xs: 6 }}>
                        <div className="py-1 py-lg-3 text-center">
                          {isIconName(item.name) && (
                            <>
                              <span className="services-icons">
                                <Icon className="service-icon" fontSize={30} name={item.name as IconName} />
                              </span>
                              <h6 className="pt-3 small">{item.iconTitle}</h6>
                            </>
                          )}
                        </div>
                      </b2x.Col>
                    ))}
                  </b2x.Row>
                </Container>
              )}
            </div>
            <b2x.CustomerForm className="mb-4" />
          </b2x.Col>
        </b2x.Row>
      </Container>
    </Page>
  );
};
